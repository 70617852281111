.calendar-cell {
  border: 1px solid #ccc;
  width: calc(14.28% - 10px);  /* 7 days per week */
  margin: 5px;
  padding: 10px;
  box-sizing: border-box;
  background: #f9f9f9;
}

.empty-cell {
  width: calc(14.28% - 10px);
  margin: 5px;
  height: 100px;  /* Adjust height as needed */
}
